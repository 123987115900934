<template>
  <div
    :class="[
      'menu-section',
      `px-${sectionPadding}`,
      { subsection: isSubsection },
      { isDirty },
      entity.section_type ? `type--${entity.section_type}` : '',
      { callout: entity.is_callout },
      { 'has-title': entity.title },
    ]"
    @mouseover.stop="isHovering = true"
    @mouseleave.stop="isHovering = false"
  >
    <slot>
      <div
        v-if="entity.section_type !== 'hide_title'"
        class="menu-section--top text-xs-center mb-3"
      >
        <BaseEditable
          v-if="original.title || isShowingEditUI"
          ref="title"
          tag="span"
          :content="original.title"
          :mods="myMods.title"
          class="menu-section--title hello-bloomie--font"
          @update="updateSectionAction('title', $event)"
          @dirtyUpdate="adjustDirtScore"
        />
        <div v-if="entity.section_subtitle_note" class="menu-section--subtitle">
          <BaseEditable
            tag="span"
            :content="original.section_subtitle_note"
            :mods="myMods.section_subtitle_note"
            class="rubik--font"
            @update="
              updateSectionAction('section_subtitle_note', $event, entity.id)
            "
            @dirtyUpdate="adjustDirtScore"
          />
        </div>
      </div>
      <div
        :class="[
          'menu-section--items',
          { 'text-xs-center': entity.section_type === 'sides' },
        ]"
      >
        <BaseButton
          v-if="!itemIds.length && isShowingEditUI"
          md-icon="add"
          text-color="green"
          color="green"
          small
          round
          outline
          @click="stageNewItem({ sectionId: entity.id })"
          >Add an Item</BaseButton
        >
        <BaseMenuComponent
          v-else
          name="MenuSectionItems"
          :item-ids="itemIds"
          :section="entity"
          item-class="asap--font"
          @addItem="addItemAction({ sectionId: entity.id, index: $event })"
          @deleteSectionItem="
            removeItemAction({ sectionId: entity.id, itemId: $event.id })
          "
          @itemUpdated="updateItemAction(entity.id, $event)"
          @dragEvent="dragEventAction"
        />
      </div>
      <div v-if="subsections.length" class="menu-section--sub-menus">
        <WithMenuSection
          v-for="subsection of subsections"
          :id="subsection.id"
          v-slot="{
            isSectionLoading: isSubsectionLoading,
            section: theSubsection,
            itemMods: subsectionItemMods,
          }"
          :key="subsection.id"
        >
          <BaseMenuComponent
            :is="subsection.is_callout ? 'MenuSectionCallout' : 'MenuSection'"
            :item-mods="subsectionItemMods"
            :is-subsection="true"
            :entity="theSubsection"
            @updateTitle="
              updateSectionAction('title', $event, theSubsection.id)
            "
            @updateSubtitle="
              updateSectionAction(
                'section_subtitle_note',
                $event,
                theSubsection.id
              )
            "
            @dirtyUpdate="adjustDirtScore"
          />
        </WithMenuSection>
      </div>
      <!-- <EntityModButtons
      v-if="isHovering && isShowingEditUI"
      class="section-buttons--wrap"
      bottom
      add-btn-color="blue"
      entity-name="Section"
      show-delete
      :hide-buttons="isSelected && !isHovering"
      @add="$emit('addSectionAfter')"
      @delete="$emit('deleteSection')"
    /> -->
    </slot>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import MenuSectionMixin from '@mixins/menu-section'

import MenuSectionCallout from '@menu/MenuSectionCallout'
import EntityModButtons from '@menu/EntityModButtons'

export default {
  name: 'BlueOakHSVMenuSection',
  components: { MenuSectionCallout, Draggable, EntityModButtons },
  mixins: [MenuSectionMixin],
}
</script>

<style lang="scss">
@import '@design';

.menu-section {
  position: relative;
  margin-bottom: 2.5em;
  &.subsection {
    margin-top: 50px;
    margin-bottom: 0;
  }
  &:not(.subsection):not(.callout) {
    padding-top: 0.5em;
  }

  &.isDirty {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: aquamarine;
      transform: translateY(-100%);
    }
  }
  &.callout {
    padding-top: 1.3em;
    padding-bottom: 1.3em;
    margin-right: auto;
    margin-left: auto;
    border: 2px solid #979797;
    &.has-title .menu-section--items {
      margin: 1em 0 0;
    }
  }
} // .menu-section

.menu-section--title {
  margin-bottom: 0;
  font-size: 2.4em;
  line-height: 1.1;
  &.placeholder {
    position: relative;
    z-index: 10;
    font-style: italic;
    color: darken(white, 25%) !important;
  }
}

.menu-section--subtitle .resto-editable {
  font-size: 0.95em;
  font-weight: 400 !important;
  text-transform: uppercase;
}

.menu-entity--buttons-wrap.Section-buttons {
  z-index: 929;

  $distance: 30px;

  &.top {
    top: 0;
    padding-bottom: $distance;
    transform: translateY(-100%);
  }
  &.bottom {
    top: auto;
    bottom: 0;
    padding-top: $distance;
    transform: translateY(100%);
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 2s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0.1;
  transform: translateY(100%);
}
</style>
